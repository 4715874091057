import React, { useState } from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import { animated, useSpring, config } from '@react-spring/web'
import Image from 'next/image'
import styled, { useTheme } from 'styled-components'

import LockCircleShadowed from '~/components/ui/LockCircleShadowed'
import { AreaLabel, CardShadowRadius, GameCardAura } from '~/components/ui/sharedStyledComponents'

const { Body2Bold } = fonts

export interface GameCardProps {
  image: string
  gameTitle: string
  area: string
  isLocked: boolean
}

const GameCardGamesPage: React.FC<GameCardProps> = ({ image, gameTitle, area, isLocked }) => {
  const [hoverToggle, setHoverToggle] = useState(false)
  const theme = useTheme()
  const enlarge = useSpring({
    transform: hoverToggle ? 'scale(1.1)' : 'scale(1)',
    boxShadow: hoverToggle ? '0px 0px 20px 8px rgba(14, 35, 55, 0.15)' : theme.card.shadow,
    config: config.stiff,
  })

  return (
    <GameCardAura
      as={animated.div}
      style={{ transform: enlarge.transform }}
      onMouseEnter={() => setHoverToggle(true)}
      onMouseLeave={() => setHoverToggle(false)}
    >
      {isLocked && <LockCircleShadowed />}
      <GameCardContainer as={animated.div} style={{ boxShadow: enlarge.boxShadow }}>
        <StyledImage src={image} alt={gameTitle} width='144' height='80' layout='fixed' />
        <GameTitle as='div'>{gameTitle}</GameTitle>
        <StyledAreaLabel>{area}</StyledAreaLabel>
      </GameCardContainer>
    </GameCardAura>
  )
}

export default GameCardGamesPage

const GameCardContainer = styled(CardShadowRadius)`
  width: 144px;
  height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const StyledAreaLabel = styled(AreaLabel)`
  display: block;
  position: absolute;
  bottom: 16px;
  left: auto;
  right: auto;
`

const GameTitle = styled(Body2Bold)`
  color: ${(props) => props.theme.colors.inkBase};
  padding: 9px;
`

const StyledImage = styled(Image)`
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`
