import styled from 'styled-components'

import LockCircle from '~/images/LockCircle.svg'

const LockCircleShadowed: React.FC = () => {
  return (
    <Container>
      <LockCircle title='lock' width={32} height={32} />
    </Container>
  )
}

export default LockCircleShadowed

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
`
