import { useReactiveVar } from '@apollo/client'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import type { GameProgressDictionary } from '~/components/GameCardList/GameCardList'
import GameGroupHeader from '~/components/GameCardList/GameGroupHeader'
import GameCardGamesPage from '~/components/ui/GameCardGamesPage'
import { AreaSlug, AccountState, RANKS_KEYED_BY_NUMBER, RANK_COLORS } from '~/constants'
import { GamesLibraryDictionary, playableGameSlugs } from '~/gameConfigs/GamesLibrary'
import { GameProgressFragment, useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { accountStatusVar } from '~/graphql/reactive-vars'
import { byPlayCount } from '~/utils/gameListUtils'

export enum GameListType {
  byArea,
  byBadge,
}

interface GameGroupProps {
  games: GameProgressFragment[]
  rank?: Stage | undefined
  area?: AreaSlug
}

export const GameGroup: React.FC<GameGroupProps> = ({ games, area, rank }) => {
  const accountStatus = useReactiveVar(accountStatusVar)

  const { data: workout } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: playableGameSlugs },
  })

  const isSubscriber = accountStatus == AccountState.PREMIUM

  const { t } = useTranslation('common')
  const areaTitle = area ? t(`areas:${area}.name`) : ''

  // fall back to `no-badge` status if rank is undefined
  const rankTitle = t(`ranks.${RANKS_KEYED_BY_NUMBER[(rank as Stage) ?? 0]}`) || ''

  const toGameCard = ({ game }: GameProgressFragment, index: number) => {
    const gameTitle = t(`gameNamesAndDescriptions:${game.slug}.name`)
    const area = t(`areas:${game.areaSlug}.name`)

    // lock games not in workout for unsubscribed users
    const isInWorkout = !!workout?.todaysWorkout.workoutGames.find((workoutGame) => workoutGame.game.slug === game.slug)
    const isLocked = !isSubscriber && !isInWorkout

    return (
      <Link key={index} href={`/game/${game.slug}`} passHref>
        <a href='replace'>
          <GameCardGamesPage
            image={GamesLibraryDictionary[game.slug].gamesPageImage}
            gameTitle={gameTitle}
            area={area}
            isLocked={isLocked}
          />
        </a>
      </Link>
    )
  }

  const containerProps = rank
    ? {
        badgeBorder: true,
        isActive: games.length > 0, // if there are no games, group will have a dashed border
        borderColor: RANK_COLORS[rank ?? 0],
      }
    : {}

  // if grouping by badge, sort games by play count
  // if grouping by area, list games in alphabetical order
  const listContent = rank !== undefined ? games.sort(byPlayCount).map(toGameCard) : games.map(toGameCard)

  return (
    <>
      {listContent.length > 0 && (
        <GameGroupContainer>
          <GameGroupHeader rank={rank} areaTitle={areaTitle} rankTitle={rankTitle} />
          <GameListContainer {...containerProps}>{listContent}</GameListContainer>
        </GameGroupContainer>
      )}
    </>
  )
}

/**
 * GamesList maps a game list grouped by either area or badge to
 * appropriate groups according to the type of list.
 * @returns Collection of GameGroups or an empty fragment
 */
export const GameGroupList = ({
  gamesList,
  listType,
}: {
  gamesList: GameProgressDictionary
  listType: GameListType
}) => {
  const areas = Object.values(AreaSlug)
  if (listType === GameListType.byArea) {
    return (
      <>
        {areas.map((area, index: number) => (
          <GameGroup games={gamesList[area] ?? []} key={index} area={area} />
        ))}
      </>
    )
  }

  if (listType === GameListType.byBadge) {
    return (
      <>
        {Object.entries(gamesList)
          .reverse()
          .map(([rank, gamesAtRank], index: number) => (
            <GameGroup games={gamesAtRank} key={index} rank={+rank as Stage} />
          ))}
      </>
    )
  }

  return <></>
}

export default GameGroupList

const coloredLeftBorder = css<{ isActive?: boolean; borderColor?: LumosColor }>`
  :before {
    content: '';
    /* avoid obscuring footer */
    z-index: -1;
    position: absolute;
    /* for newcomer rank, extend line to bottom of viewport */
    height: 100%;
    ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
      height: 100%;
    }
    width: 3px;
    /* set svg line as background image */
    background-image: ${({ theme, isActive = false, borderColor }) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23${
        /* '#' must be url-encoded on hex code; remove '#' from theme-provided hex and add url-encoded '#' (%23) */
        (isActive ? theme.colors[borderColor ?? 'blackBase'] : theme.colors.coolGray82).split('#')[1]
      }' %3E%3Cline stroke-dasharray='${
        isActive ? '0 0' : '1 6' /* solid line for active ranks */
      }' stroke-linecap='round' stroke-width='3px' x1='50%25' x2='50%25' y1='99%25' y2='2px' /%3E%3C/svg%3E")`};
  }
`

const GameGroupContainer = styled.div`
  width: 100%;
`

const GameListContainer = styled.div<{
  badgeBorder?: boolean
  borderColor?: LumosColor
}>`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-row-gap: 12px;
  grid-column-gap: 5px;
  margin-left: ${({ badgeBorder }) => (badgeBorder ? '0.85em' : '-8px')};
  padding: ${({ badgeBorder }) => (badgeBorder ? '18px 0 40px 25px' : '18px 0 64px 0')};
  position: relative;
  ${({ badgeBorder }) => badgeBorder && coloredLeftBorder};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* at this screen size, unbadged game group should be centered; 
       badge groups should keep their alignment
    */
    justify-content: ${({ badgeBorder }) => (badgeBorder ? 'initial' : 'center')};
  } ;
`
