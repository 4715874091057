import { useReactiveVar } from '@apollo/client'
import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import styled from 'styled-components'

import ButtonLink from '~/components/ButtonLink'
import { AccountState } from '~/constants'
import { accountStatusVar } from '~/graphql/reactive-vars/accountStatusVar'
import ChevronRight from '~/images/chevron_right.svg'
import PremiumLearn from '~/images/PremiumLearn.svg'
import PremiumTry from '~/images/PremiumTry.svg'

const { Body1Bold, Body3, MicroText1BoldCaps } = fonts

const { publicRuntimeConfig } = getConfig()

const PremiumBanner = () => {
  const isResearch = publicRuntimeConfig.researchMode

  const { t } = useTranslation('common')
  const accountStatus = useReactiveVar(accountStatusVar)

  const isPremium = accountStatus === AccountState.PREMIUM
  const isFreeTrialEligible = accountStatus === AccountState.FREE_TRIAL_ELIGIBLE
  const textKey = isFreeTrialEligible ? 'freeTrialBanner' : 'freeBanner'

  if (isPremium || isResearch) {
    return <></>
  } else {
    return (
      <BannerContainer>
        <ContentHolder>
          <ContentRow>
            <SvgContainer>
              {isFreeTrialEligible ? <PremiumTry title='Try Premium' /> : <PremiumLearn title='Learn More' />}
            </SvgContainer>
            <TextAndButtonContainer>
              <TextContainer>
                <Header>{t(`banners.${textKey}.header`)}</Header>
                <Body>{t(`banners.${textKey}.body`)}</Body>
              </TextContainer>
              <ButtonContainer>
                <ButtonLink
                  // TODO: link to account upgrade
                  href='/settings'
                  rightBadge={<ChevronRight title='Next' height='10px' />}
                >
                  <MicroText1BoldCaps>{t(`buttons.${textKey}`)}</MicroText1BoldCaps>
                </ButtonLink>
              </ButtonContainer>
            </TextAndButtonContainer>
          </ContentRow>
        </ContentHolder>
      </BannerContainer>
    )
  }
}

const BannerContainer = styled.div`
  display: grid;
  grid-template-rows: minmax(120px, max-content) 1fr;
  grid-column-gap: 1%;
  grid-template-columns: repeat(8, 1fr);
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.whiteBase};
  box-shadow: ${(props) => props.theme.card.shadow};
  padding-left: 160px;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    padding-left: 120px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.desktopSmall} {
    padding-left: 120px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    padding: 0px 5%;
    grid-template-columns: repeat(4, 1fr);
  }
`

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`

const ContentHolder = styled.div`
  grid-column: 2/-1;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    grid-column: 1/-1;
  }
`

const SvgContainer = styled.div`
  font-size: 64px;
  margin: 20px 32px 0px 0px;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    margin: 20px 16px 0px 0px;
  }
`

const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    max-width: 70vw;
  }
`

const Header = styled(Body1Bold)`
  display: block;
  margin-top: 24px;
`

const Body = styled(Body3)`
  display: block;
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  > button {
    padding-left: 34px;
    ${({ theme }) => theme.mediaQuery.maxWidth.mobile} {
      padding: 0 15px 2px 20px;
    }
  }
  margin: auto 56px;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktopSmall} {
    margin: auto 20px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    margin: 0 0 24px 0;
  }
`

export default PremiumBanner
