import { groupBy } from 'lodash'

import type { GameProgressDictionary } from '~/components/GameCardList/GameCardList'
import { RANKS_KEYED_BY_NAME, REQUIRED_PLAYS } from '~/constants'
import { GameProgressFragment } from '~/graphql/generated/schema'

export const normalizeProgressByBadge = (groupedGames: GameProgressDictionary) => {
  const clonedGroupedGames = Object.assign({}, groupedGames)
  let highestRank
  for (let rankIndex = 5; rankIndex >= 1; rankIndex--) {
    // descending through ranks to find the highest overall rank user has reached
    if (!highestRank && !clonedGroupedGames[rankIndex]) {
      // user has not reached current rank in any game; break out and check next rank
      continue
    }
    if (!highestRank) {
      // user has game at current rank & has no games at higher ranks => this is highest rank
      highestRank = rankIndex
    }
    if (highestRank && !clonedGroupedGames[rankIndex]) {
      // this is not highest rank, but no games at this rank; create empty set for rank
      clonedGroupedGames[rankIndex] = []
    }
  }

  return clonedGroupedGames
}

export const byPlayCount = (firstGame: GameProgressFragment, secondGame: GameProgressFragment): ComparisonValue => {
  const { playsAtRank: firstGamePlayCount, lastGamePlay: firstGameLastPlayedAt } = firstGame
  const { playsAtRank: secondGamePlayCount, lastGamePlay: secondGameLastPlayedAt } = secondGame
  if (firstGamePlayCount > secondGamePlayCount) {
    return -1
  }
  if (firstGamePlayCount < secondGamePlayCount) {
    return 1
  }
  // if games have been played an equal number of times
  if (firstGamePlayCount === secondGamePlayCount) {
    // sort by date last played
    if (firstGameLastPlayedAt?.finishedAt === undefined || secondGameLastPlayedAt?.finishedAt === undefined) {
      return 0
    }
    if (firstGameLastPlayedAt.finishedAt === secondGameLastPlayedAt.finishedAt) {
      return 0
    }
    return firstGameLastPlayedAt.finishedAt > secondGameLastPlayedAt.finishedAt ? -1 : 1
  }
  return 0
}

export const getBadgeGroup = (game: GameProgressFragment | undefined) => {
  const { rank, playsAtRank } = game ?? { rank: 'newcomer', playsAtRank: 0 }
  const rankIndex = RANKS_KEYED_BY_NAME[rank]
  // playsAtRank can only be greater than the badge requirement when the highest badge/rank has been achieved
  // assumes backend query does not violate this
  if (playsAtRank >= REQUIRED_PLAYS[rankIndex]) {
    return rankIndex
  } else {
    return rankIndex - 1
  }
}

export const groupByBadge = (gameStats: GameProgressFragment[]) => groupBy(gameStats, getBadgeGroup)
export const groupByArea = (gameStats: GameProgressFragment[]) => groupBy(gameStats, (game) => game.game.areaSlug)
