import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import { Badge, GoalStatus } from '~/components/AchievementCard'
import BadgeImage from '~/components/BadgeImage'
import ActiveBadges from '~/images/Badges/Active'
import BadgeBackground from '~/images/Badges/badge-background.svg'

const { Body2, Body2BoldCaps } = fonts

export const GameGroupHeader = ({
  rank,
  areaTitle,
  rankTitle,
}: {
  rank?: Stage | undefined
  areaTitle: string
  rankTitle: string
}) => {
  const { t } = useTranslation('games')
  const noBadgesDescription = t('noBadges.description')
  const noBadgesInstruction = t('noBadges.instruction')

  if (rank === undefined) {
    // no rank -- header without icon
    return (
      <AreaListHeader>
        <GroupTitle>{areaTitle}</GroupTitle>
      </AreaListHeader>
    )
  }

  if (rank === 0) {
    // rank of `0` -- large header div with potential badges and message
    return (
      <UnbadgedGroupHeader>
        <UnbadgedHeaderContent>
          <BadgeRow>
            <ActiveBadges.rank1 title='active-badge-1' />
            <ActiveBadges.rank2 title='active-badge-2' />
            <ActiveBadges.rank3 title='active-badge-3' />
            <ActiveBadges.rank4 title='active-badge-4' />
            <ActiveBadges.rank5 title='active-badge-5' />
          </BadgeRow>
          <div>
            <Body2 as='div'>{noBadgesDescription}</Body2>
            <Body2 as='div'>{noBadgesInstruction}</Body2>
          </div>
        </UnbadgedHeaderContent>
      </UnbadgedGroupHeader>
    )
  }

  // valid non-zero rank -- header with icon
  return (
    <BadgeGroupHeader>
      <BadgeImageContainer>
        <StyledBadgeBackground title='badge-backround-hexagon' />
        <BadgeImage badge={rank as Badge} badgeStatus={GoalStatus.Complete} />
      </BadgeImageContainer>
      <GroupTitle>{rankTitle}</GroupTitle>
    </BadgeGroupHeader>
  )
}

export default GameGroupHeader

const AreaListHeader = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
`

const BadgeGroupHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  padding-left: 0.5px;
`

const BadgeImageContainer = styled.span`
  font-size: 1.8em;
  padding-right: 12px;
  color: ${({ theme }) => theme.colors.orangeBase};
  display: grid;
  > * {
    grid-area: 1 / -1;
  }
`

const BadgeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
  padding-top: 5px;
  padding-bottom: min(3%, 20px);
  padding-right: 10px;
  padding-left: 10px;
  > svg {
    font-size: 2.5em;
  }
`

const GroupTitle = styled(Body2BoldCaps)`
  color: ${({ theme }) => theme.colors.inkBase};
`

const StyledBadgeBackground = styled(BadgeBackground)`
  z-index: -1;
  transform: scale(1.8);
`

const UnbadgedHeaderContent = styled.div`
  grid-column: 1 / -1;
  width: calc(100% - 14px);
  background-color: ${({ theme }) => theme.colors.whiteBase};
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.card.shadow} inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 25px 20px;
  color: ${({ theme }) => theme.colors.coolGray44};
  div {
    text-align: center;
  }
`

const UnbadgedGroupHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-column-gap: 5px;
  /* negative margin keeps the width of this div in sync with cards in GameListContainer */
  margin-right: -8px;
  padding-top: 25px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    justify-content: center;
  }
`
